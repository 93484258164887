import React from "react";
import { Helmet } from "react-helmet";

export default function HelmetCom(props) {
  return (
    <div>
      <Helmet>
        <title>{((props && props.pageName) ? props.pageName+": " : "")}World's first discount broking crypto exchange</title>
        <meta name="title" content="World's first discount broking crypto exchange - bitbab exchange" />
        <meta name="description" content="bitbab is the leading crypto trading platform, enabling the buying or selling of Bitcoin and other cryptocurrencies in real-time with lowest fees of 0.05% in FBT"></meta>
        <meta name="keywords" content="crypto exchange, Crypto trading platform, crypto token, digital currencies, crypto exchange lowest fees, Cryptocurrency Exchange, Crypto Trading in India, buy and sell digital currency, best crypto to buy now, cryptocurrency prices, top 10 cryptocurrency" />

        <meta property="og:image" content="https://tnxexchange.s3.amazonaws.com/bitbab/prod1/exchange/Images/settings/images[]-1715631539310" />
        <meta property="og:image:type" content="image/jpg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="627" />
        <meta property="og:image:alt" content="World's first discount broking crypto exchange - bitbab exchange" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://bitbab.com/" />
        <meta property="og:title" content="World's first discount broking crypto exchange - bitbab exchange" />
        <meta property="og:description" content="bitbab is the leading crypto trading platform, enabling the buying or selling of Bitcoin and other cryptocurrencies in real-time with lowest fees of 0.05% in FBT" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="World's first discount broking crypto exchange - bitbab exchange" />
        <meta name="twitter:description" content="bitbab is the leading crypto trading platform, enabling the buying or selling of Bitcoin and other cryptocurrencies in real-time with lowest fees of 0.05% in FBT" />
        <meta name="twitter:site" content="@bitbab" />
        <meta name="twitter:image" content="bitbab is the leading crypto trading platform, enabling the buying or selling of Bitcoin and other cryptocurrencies in real-time with lowest fees of 0.05% in FBT" />
        <meta name="twitter:creator" content="@bitbab" />
      </Helmet>
    </div>
  );
}
